<template>
  <v-footer
    id="home-footer"
    color="grey darken-4"
    dark
    min-height="72"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <div class="d-flex flex-wrap justify-md-start justify-center justify-md-none">
            <template v-for="(s, i) of social">
              <base-icon
                v-if="s.icon"
                :key="`avatar${i}`"
                size="20"
                class="mx-1"
              >
                {{ s.icon }}
              </base-icon>
              <a
                :key="`social${i}`"
                class="white--text pa-1 pa-md-0"
                :href="s.link"
                :target=" s.newWindow ? '_blank' : '_self'"
                v-text="s.title"
              />
              <v-responsive
                v-if="i < social.length "
                :key="`divider-${i}`"
                class="mx-4 shrink hidden-sm-and-down"
                max-height="24"
              >
                <v-divider vertical />
              </v-responsive>
            </template>
            <template v-for="(l, k) of legal">
              <router-link
                :key="`router-${k}`"
                :to="{ name: l.router }"
                class="primary--text pa-1 pa-md-0"
              >
                {{ l.title }}
              </router-link>

              <v-responsive
                v-if="k < legal.length -1"
                :key="`divider-legal-${k}`"
                class="mx-4 shrink hidden-sm-and-down"
                max-height="24"
              >
                <v-divider vertical />
              </v-responsive>
            </template>
          </div>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <div class="d-flex flex-wrap justify-md-start justify-center justify-md-none ">
            <v-spacer class="hidden-xs-only" />
            <span class="mx-2">Copyright &copy; 2020 - {{ CopyrightYear }}</span>
            <span class="d-flex flex-nowrap">
              <base-img
                :src="require('@/assets/logo-dark.svg')"
                class="pa-1 pa-md-0 "
                contain
                max-width="30"
                width="100%"
              />
              <router-link
                tag="span"
                to="/"
                class="mx-1 ml-2 link"
              >
                Aptamimetics GmbH
              </router-link>
            </span>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-container class="text-center">
          <span class="text-uppercase grey--text text--darken-2">supported by</span>
        </v-container>
      </v-row>

      <v-row
        align="self-center"
        justify="center"
      >
        <template
          v-for="(support,i) in items"
        >
          <div
            :key="i"
            class="d-flex flex-row mx-1 my-1"
          >
            <v-card
              class="elevation-2"
              color="white pa-2"
            >
              <base-img
                :src="require(`@/assets/logo/${support.logo}`)"
                color="white"
                contain
                height="70"
                width="128"
              />
            </v-card>

            <v-responsive
              v-if="i < (itemlength - 1)"
              :key="`divider-${i}`"
              class="text-center hidden-sm-and-down"
              height="90"
              width="48"
            >
              <v-divider vertical />
            </v-responsive>
          </div>
        </template>
      </v-row>
      <!--
      <v-row>
        <v-col cols="12">
          <base-section
            id="SupportersFooter"
            space="10"
          >
            <v-container>
              <v-slide-group>
                <template v-for="(support,i) in items">
                  <v-slide-item
                    :key="i"
                    class="align-self-center"
                  >
                    <v-sheet
                      color="grey lighten-3"
                      class="py-2 px-2"
                      shaped
                    >
                      <base-img
                        :src="require(`@/assets/logo/${support.logo}`)"
                        color="white"
                        contain
                        height="70"
                        width="128"
                      />
                    </v-sheet>
                  </v-slide-item>

                  <v-responsive
                    v-if="i < (itemlength - 1)"
                    :key="`divider-${i}`"
                    class="text-center"
                    height="90"
                    width="48"
                  >
                    <v-divider vertical />
                  </v-responsive>
                </template>
              </v-slide-group>
            </v-container>
          </base-section>
        </v-col>
      </v-row> -->
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'HomeFooter',

    data: () => ({
      supporters: [
        {
          logo: 'l-bank.jpg',
          name: 'Landeskreditbank Baden-Württemberg',
          exp: false,
        },
        {
          logo: 'BMBF.png',
          name: 'Bundesministerium für Bildung und Forschung',
          exp: false,
        },
        {
          logo: 'BMEL.png',
          name: 'Bundesministerium für Ernährung und Landwirtschaft',
          exp: false,
        },
        {
          logo: 'investBW.png',
          name: 'Invest BW: Innovationsförderung',
          exp: false,
        },
        {
          logo: 'Life-Science-Accelerator.png',
          name: 'Life Science Accelerator Mannheim',
          exp: false,
        },
        {
          logo: 'Start-Up-BW-Accelerator.png',
          name: 'Start-Up BW',
          exp: false,
        },
        {
          logo: 'BadenCampus.png',
          name: 'BadenCampus',
          exp: false,
        },
        {
          logo: 'ESF-EU-Logo.jpg',
          name: 'ESF EU',
          exp: false,
        },
        {
          logo: 'ESF-BW-Logo.jpg',
          name: 'ESF BW',
          exp: false,
        },
        {
          logo: 'invest.png',
          name: 'Invest',
          exp: '2023-12-11',
        },

      ],
      social: [
        // {
        //   title: 'Twitter',
        //   link: 'https://twitter.com/aptamimetics',
        //   icon: 'fab fa-twitter',
        //   newWindow: true,
        // },
        {
          title: 'LinkedIn',
          link: 'https://www.linkedin.com/company/aptamimetics',
          icon: 'fab fa-linkedin',
          newWindow: true,
        },
      ],
      legal: [
        {
          title: 'Privacy Policy',
          router: 'Privacy Policy',
        },
        {
          title: 'Terms and Conditions',
          router: 'Legal Disclosure',
        },
        {
          title: 'Legal Disclosure',
          router: 'Legal Disclosure',
        },
      ],
    }),

    computed: {
      items () {
        const today = new Date().getTime()

        return this.supporters.filter((el) => el.exp === false || today <= new Date(el.exp).getTime())
      },

      itemlength () {
        return this.items.length
      },
      CopyrightYear () {
        const currentDate = new Date()

        return currentDate.getFullYear()
      },
    },

  }
</script>

<style lang="sass">
  #home-footer a
    text-decoration: none

  #home-footer .link:hover
    cursor: pointer
    text-decoration: underline white

</style>
